import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { BsGrid, BsList } from 'react-icons/bs'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'
import {
  type ViewType,
  ViewTypeValue
} from '../types/order-request-details-summary-view-type'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import ToggleButtonGroup from '../../common/components/ToggleButtonGroup'
import styles from '../assets/scss/OrderRequest.module.scss'

export interface OrderRequestSpendPanelHeaderControlsProps {
  activeView: ViewType
  compactView: boolean
  allCollapsed?: boolean
  canAdd: boolean
  canGroupEdit: boolean
  itemsSelected: boolean
  onExpandAll: () => void
  onCollapseAll: () => void
  onViewChange: (view: ViewType) => void
  onAddNew: () => void
  onGroupEdit: () => void
}

function ActionMenu ({
  canAdd,
  compactView,
  allCollapsed,
  canGroupEdit,
  itemsSelected,
  onAddNew,
  onExpandAll,
  onCollapseAll,
  onGroupEdit
}: Readonly<Partial<OrderRequestSpendPanelHeaderControlsProps>>): ReactElement {
  const { t } = useTranslation()

  return (
    <MaActionMenu aria-label={t('order_request.spend.menu')}>
      {compactView && (
        <>
          {allCollapsed ? (
            <MaActionMenuItem onClick={onExpandAll}>
              {t('common.button.labels.expand_all')}
            </MaActionMenuItem>
          ) : (
            <MaActionMenuItem onClick={onCollapseAll}>
              {t('common.button.labels.collapse_all')}
            </MaActionMenuItem>
          )}
        </>
      )}
      {canGroupEdit && (
        <MaActionMenuItem onClick={onGroupEdit} disabled={!itemsSelected}>
          {t('order_request.action.line_item.group_edit')}
        </MaActionMenuItem>
      )}
      {canAdd && (
        <MaActionMenuItem onClick={onAddNew}>
          {t('order_request.action.line_item.add')}
        </MaActionMenuItem>
      )}
    </MaActionMenu>
  )
}

function OrderRequestSpendPanelHeaderControls ({
  activeView = ViewTypeValue.grouped,
  compactView,
  allCollapsed,
  canAdd,
  canGroupEdit,
  itemsSelected,
  onExpandAll,
  onCollapseAll,
  onViewChange,
  onAddNew,
  onGroupEdit
}: Readonly<OrderRequestSpendPanelHeaderControlsProps>): ReactElement {
  const { t } = useTranslation()

  const handleChangeView = (): void => {
    onViewChange(
      activeView === ViewTypeValue.grouped
        ? ViewTypeValue.table
        : ViewTypeValue.grouped
    )
  }

  const handleToggleCollapsed = (): void => {
    allCollapsed ? onExpandAll() : onCollapseAll()
  }

  return compactView ? (
    <ActionMenu
      canAdd={canAdd}
      canGroupEdit={canGroupEdit}
      itemsSelected={itemsSelected}
      compactView={compactView}
      allCollapsed={allCollapsed}
      onAddNew={onAddNew}
      onCollapseAll={onCollapseAll}
      onExpandAll={onExpandAll}
      onGroupEdit={onGroupEdit}
    />
  ) : (
    <div className={styles.controls}>
      {(compactView || activeView === ViewTypeValue.grouped) && (
        <Button
          variant="outline-primary"
          className={classNames(styles['expand-collapse-btn'], 'rounded')}
          onClick={handleToggleCollapsed}
        >
          {allCollapsed
            ? t('common.button.labels.expand_all')
            : t('common.button.labels.collapse_all')}
        </Button>
      )}

      <ToggleButtonGroup
        onClick={handleChangeView}
        selectedBtnIndex={activeView === ViewTypeValue.grouped ? 0 : 1}
      >
        <BsGrid size={24} title={t('common.view.grouped')} />
        <BsList size={24} title={t('common.view.table')} />
      </ToggleButtonGroup>

      <ActionMenu
        canAdd={canAdd}
        canGroupEdit={canGroupEdit}
        itemsSelected={itemsSelected}
        onAddNew={onAddNew}
        onGroupEdit={onGroupEdit}
      />
    </div>
  )
}

export default OrderRequestSpendPanelHeaderControls
