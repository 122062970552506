import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import {
  type OrderRequestsApiUpdateOrderRequestLineItemRequest,
  type OrderRequestLineItem,
  type OrderRequestLineItemsResource,
  type OrderRequestsApiDeleteOrderRequestLineItemRequest,
  type OrderRequestsApiAddOrderRequestLineItemRequest,
  type ProductResource
} from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import useOrderRequestSummary from './useOrderRequestSummary'

export interface UseOrderRequestLineItemsHook {
  count: number
  currency: string
  total: number
  isLoading: boolean
  isValidating: boolean
  isAdding: boolean
  isUpdating: boolean
  isRemoving: boolean
  errorAdding?: Error
  lineItems: OrderRequestLineItem[]
  addLineItem: (
    productResource: ProductResource
  ) => Promise<OrderRequestLineItem | undefined>
  updateLineItem: (
    lineItem: OrderRequestLineItem
  ) => Promise<OrderRequestLineItem>
  removeLineItem: (lineItem: OrderRequestLineItem) => Promise<void>
  groupUpdateLineItems: (lineItems: OrderRequestLineItem[]) => Promise<void>
}

function useOrderRequestLineItems (
  orderRequestId = ''
): UseOrderRequestLineItemsHook {
  const { activeAccount } = useAccounts()
  const {
    orderRequestsApi: {
      getOrderRequestLineItems,
      addOrderRequestLineItem,
      updateOrderRequestLineItem,
      deleteOrderRequestLineItem
    },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading, isValidating } = useSWR<
    OrderRequestLineItemsResource,
    Error
  >(
    accountId && orderRequestId
      ? ['order-request-line-items', orderRequestId, accountId]
      : null,
    async () =>
      await fetcher(getOrderRequestLineItems, { orderRequestId, accountId })
  )

  const {
    trigger: triggerAdd,
    isMutating: isAdding,
    error: errorAdding
  } = useSWRMutation(
    accountId ? ['order-request-line-items', orderRequestId, accountId] : null,
    async (
      _,
      { arg }: { arg: OrderRequestsApiAddOrderRequestLineItemRequest }
    ) => await fetcher(addOrderRequestLineItem, arg),
    { populateCache: false, revalidate: true, throwOnError: true }
  )

  const { trigger: triggerUpdate, isMutating: isUpdating } = useSWRMutation(
    accountId ? ['order-request-line-items', orderRequestId, accountId] : null,
    async (
      _,
      { arg }: { arg: OrderRequestsApiUpdateOrderRequestLineItemRequest }
    ) => await fetcher(updateOrderRequestLineItem, arg),
    { populateCache: false, revalidate: true }
  )

  const { trigger: triggerRemove, isMutating: isRemoving } = useSWRMutation(
    accountId ? ['order-request-line-items', orderRequestId, accountId] : null,
    async (
      _,
      { arg }: { arg: OrderRequestsApiDeleteOrderRequestLineItemRequest }
    ) => {
      await fetcher(deleteOrderRequestLineItem, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const addLineItem = async (
    productResource: ProductResource
  ): Promise<OrderRequestLineItem | undefined> => {
    const newLineItem: OrderRequestLineItem = {
      line_item: {
        id: '0',
        product: productResource,
        quantity: 1,
        issues: [],
        spend_categories: []
      },
      product_price: 0,
      product_snapshot: productResource
    }

    return await triggerAdd({
      orderRequestLineItem: newLineItem,
      orderRequestId,
      accountId
    })
  }

  const updateLineItem = async (
    lineItem: OrderRequestLineItem
  ): Promise<OrderRequestLineItem> =>
    await triggerUpdate({
      orderRequestLineItem: lineItem,
      orderRequestId,
      lineItemId: lineItem.line_item.id,
      accountId
    })

  const removeLineItem = async (
    lineItem: OrderRequestLineItem
  ): Promise<void> => {
    await triggerRemove(
      {
        lineItemId: lineItem.line_item.id,
        orderRequestId,
        accountId
      },
      {
        optimisticData: (_currentData): OrderRequestLineItemsResource =>
          // Note that currentData provided by default is not used here:
          // it is falling behind while isMutating is still true
          // during multiple overlapping subsequent requests,
          // so the active cache data returned from the useSWR hook is used instead.
          ({
            ...data,
            content: lineItems.filter(
              ({ line_item: { id } }) => lineItem.line_item.id !== id
            )
          }),
        revalidate: false
      }
    )
  }

  const groupUpdateLineItemsMutation = async (
    lineItems: OrderRequestLineItem[]
  ): Promise<OrderRequestLineItemsResource> =>
    await Promise.all(
      lineItems.map(
        async lineItem =>
          await fetcher(updateOrderRequestLineItem, {
            orderRequestId,
            lineItemId: lineItem.line_item.id,
            orderRequestLineItem: lineItem,
            accountId
          })
      )
    ).then(result => ({
      content: result
    }))

  const { trigger: triggerGroupUpdateLineItems } = useSWRMutation(
    accountId ? ['order-request-line-items', orderRequestId, accountId] : null,
    async (_, { arg }: { arg: OrderRequestLineItem[] }) =>
      await groupUpdateLineItemsMutation(arg)
  )

  const groupUpdateLineItems = async (
    lineItems: OrderRequestLineItem[]
  ): Promise<void> => {
    await triggerGroupUpdateLineItems(lineItems, {
      optimisticData: currentData => ({
        ...currentData,
        content: currentData?.content?.map(
          currentLineItem =>
            lineItems.find(
              lineItem => lineItem.line_item.id === currentLineItem.line_item.id
            ) || currentLineItem
        )
      }),
      revalidate: false
    })
  }

  const lineItems = data?.content ?? []

  const { getTotal } = useOrderRequestSummary(lineItems)

  const count = lineItems.length

  const currency = lineItems[0]?.line_item.currency ?? 'GBP'

  const total = getTotal(currency)

  return {
    lineItems,
    count,
    currency,
    total,
    isLoading,
    isValidating,
    isAdding,
    isUpdating,
    isRemoving,
    errorAdding,
    addLineItem,
    updateLineItem,
    removeLineItem,
    groupUpdateLineItems
  }
}

export default useOrderRequestLineItems
