import { type ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { type OrderRequestLineItem } from '@amici/myamici-api-client'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import MaConfirm from '../../common/components/MaConfirm'
import {
  LineItemsUpdatingStateActions,
  useUpdatingLineItemsStateDispatch
} from './OrderRequestUpdatingLineItemsProvider'

export interface OrderRequestRemoveLineItemModalProps {
  orderRequestId: string
  lineItem?: OrderRequestLineItem
  onClose: () => void
}

function OrderRequestRemoveLineItemModal ({
  orderRequestId,
  lineItem,
  onClose
}: Readonly<OrderRequestRemoveLineItemModalProps>): ReactNode {
  const { t } = useTranslation()
  const { removeLineItem } = useOrderRequestLineItems(orderRequestId)
  const { showToastMessage } = useToastNotification()
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequestId)
  const dispatch = useUpdatingLineItemsStateDispatch()

  const handleConfirm = async (): Promise<void> => {
    onClose()

    if (!lineItem) {
      return
    }

    const product = lineItem.line_item.product
    try {
      dispatch?.({ type: LineItemsUpdatingStateActions.ADD_ACTIVE_DELETION })
      await removeLineItem(lineItem)
      void refreshHistory()

      showToastMessage(
        'dark',
        <Trans
          i18nKey={t('order_request.remove.success')}
          values={{
            product_description: lineItem.line_item.product.description
          }}
          components={{
            ProductLink: <Link to={`/purchasing/products/${product.id}`} />
          }}
        />
      )
    } catch {
      showToastMessage('danger', t('order_request.remove.failure'))
    } finally {
      dispatch?.({ type: LineItemsUpdatingStateActions.REMOVE_ACTIVE_DELETION })
    }
  }

  return (
    <MaConfirm
      variant="question"
      show={lineItem}
      title={t('order_request.remove.confirmation.title')}
      closeLabel={t('common.button.labels.cancel')}
      confirmLabel={t('common.button.labels.ok')}
      onClose={onClose}
      onConfirm={handleConfirm}
    >
      <p>
        {t('order_request.remove.confirmation.line_1', {
          description: lineItem?.line_item.product.description
        })}
      </p>
      <p>{t('order_request.remove.confirmation.line_2')}</p>
    </MaConfirm>
  )
}

export default OrderRequestRemoveLineItemModal
