import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type ReactElement,
  useContext,
  useReducer
} from 'react'

const UpdatingLineItemsStateContext =
  createContext<LineItemsUpdatingState | null>(null)

const UpdatingLineItemsStateDispatchContext = createContext<Dispatch<{
  type: LineItemsUpdatingStateActions
  value?: string
}> | null>(null)

export function UpdatingLineItemsStateProvider ({
  children
}: PropsWithChildren): ReactElement {
  const [updatingState, dispatch] = useReducer(
    updatingLineItemsReducer,
    initialState
  )

  return (
    <UpdatingLineItemsStateContext.Provider value={updatingState}>
      <UpdatingLineItemsStateDispatchContext.Provider value={dispatch}>
        {children}
      </UpdatingLineItemsStateDispatchContext.Provider>
    </UpdatingLineItemsStateContext.Provider>
  )
}

export function useUpdatingLineItemsState (): LineItemsUpdatingState | null {
  return useContext(UpdatingLineItemsStateContext)
}

export function useUpdatingLineItemsStateDispatch (): Dispatch<{
  type: LineItemsUpdatingStateActions
  value?: string
}> | null {
  return useContext(UpdatingLineItemsStateDispatchContext)
}

export interface LineItemsUpdatingState {
  activeDeletionsCount: number
  activeEditingIds: string[]
}

export enum LineItemsUpdatingStateActions {
  ADD_ACTIVE_DELETION = 'ADD_ACTIVE_DELETION',
  REMOVE_ACTIVE_DELETION = 'REMOVE_ACTIVE_DELETION',
  ADD_ACTIVE_EDITING = 'ADD_ACTIVE_EDITING',
  REMOVE_ACTIVE_EDITING = 'REMOVE_ACTIVE_EDITING'
}

function updatingLineItemsReducer (
  state: LineItemsUpdatingState,
  action: { type: LineItemsUpdatingStateActions; value?: string }
): LineItemsUpdatingState {
  switch (action.type) {
    case LineItemsUpdatingStateActions.ADD_ACTIVE_DELETION: {
      return { ...state, activeDeletionsCount: state.activeDeletionsCount + 1 }
    }
    case LineItemsUpdatingStateActions.REMOVE_ACTIVE_DELETION: {
      return { ...state, activeDeletionsCount: state.activeDeletionsCount - 1 }
    }
    case LineItemsUpdatingStateActions.ADD_ACTIVE_EDITING: {
      return {
        ...state,
        activeEditingIds: [
          ...state.activeEditingIds,
          ...(action.value ? [action.value] : [])
        ]
      }
    }
    case LineItemsUpdatingStateActions.REMOVE_ACTIVE_EDITING: {
      return {
        ...state,
        activeEditingIds: state.activeEditingIds.filter(
          id => id !== action.value
        )
      }
    }
  }
}

const initialState: LineItemsUpdatingState = {
  activeDeletionsCount: 0,
  activeEditingIds: []
}
