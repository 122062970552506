import useSWR from 'swr'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import type { HistoryEvents } from '@amici/myamici-api-client'

export interface UseOrderHistoryHook {
  data: HistoryEvents | undefined
  error: any
  isLoading: boolean
}

function useOrderHistory (orderId = ''): UseOrderHistoryHook {
  const { activeAccount } = useAccounts()
  const {
    ordersApi: { getOrderHistory },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, error, isLoading } = useSWR<HistoryEvents, Error>(
    orderId && accountId ? ['order-history', orderId, accountId] : null,
    async () =>
      await fetcher(getOrderHistory, {
        orderId,
        accountId,
        size: 0,
        direction: 'desc'
      })
  )

  return {
    data,
    error,
    isLoading
  }
}

export default useOrderHistory
